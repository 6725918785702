import { defineStore } from 'pinia'
import RegimenService from '../services/regimen.service'
const _RegimenService = new RegimenService()

export const useRegimenStore = defineStore('regimenStore', {
  state: () => ({
    listado_regimen: [],
    regimen_por_paciente: []
  }),
  getters: {
    _listado_regimen: state => state.listado_regimen,
    _regimen_por_paciente: state => state.regimen_por_paciente
  },
  actions: {
  obtenerRegimen () {
    _RegimenService.listarRegimen().then(({ data }) => {
      this.listado_regimen = data
    })
  }
  }
})
