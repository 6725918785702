import { defineStore } from 'pinia'
import CalculoCuotaService from '../services/calculo-cuota.service'
const _CalculoCuotaService = new CalculoCuotaService()

export const useCalculoCuotaStore = defineStore('calculoCuotaStore', {
  state: () => ({
    cuota_calculada: 0,
    valor_max_cobro: 0,
    rangos_niveles: [],
    regimen_tipos: [],
    regimen_por_paciente: []
  }),
  getters: {
    _cuota_calculada: state => state.cuota_calculada,
    _valor_max_cobro: state => state.valor_max_cobro,
    _rangos_niveles: state => state.rangos_niveles,
    _regimen_tipos: state => state.regimen_tipos,
    _regimen_por_paciente: state => state.regimen_por_paciente
  },
  actions: {
    calcularCuota (params) {
      return _CalculoCuotaService.obtenerCuotaAnioNivel(params).then(({ data }) => {
        if (data) {
          this.cuota_calculada = parseInt(data.U_PHR_PorCobro)
          this.valor_max_cobro = parseInt(data.U_PHR_TpMxNivel)
        }
        return data
      })
    },
    obtenerRangosNiveles () {
      _CalculoCuotaService.paginate().then(({ data }) => {
        this.rangos_niveles = data.rows
        const array = []
        data.rows.map(a => {
          let str = a.regimen.nombre.toLowerCase()
          str = str.charAt(0).toUpperCase() + str.slice(1)
          if (!array.length || !array.some(e => e === str)) {
            array.push(str)
          }
        })
        this.regimen_tipos = array
      })
    }
  }
})
