
import { defineStore } from 'pinia'
import MipresService from '../services/mipres.service'
// import { useMedicamentosStore } from './medicamentos.store'
const _MipresService = new MipresService()
// const medicamentosStore = useMedicamentosStore()
export const useMipresStore = defineStore('mipresStore', {
  state: () => ({
    listado_codigos: [],
    listado_numeros_direccionamiento: []
  }),
  getters: {
    _listado_codigos: state => state.listado_codigos,
    _listado_numeros_direccionamiento: state => state.listado_numeros_direccionamiento
  },
  actions: {
    obtenerCodigos (params) {
      return _MipresService.listarCodigos(params).then(({ data }) => {
        this.listado_codigos = data
        return data
      })
    },
    validarNumerosDireccionamiento (codigo) {
      const array = []
      this._listado_codigos.filter(a => a.noprescripcion === codigo).map(a => {
        a.name = a.iddireccionamiento + ' Fec. max ent-> ' + a.fecmaxent
        array.push(a)
      })
      this.listado_numeros_direccionamiento = array
    },
    actualizarRelacionMipresCodmxCodmipres (payload) {
      return _MipresService.actualizarRelacionMipresCodMxCodMipres(payload).then(({ data }) => {
        console.log(data)
        return data
      })
    },
    actualizarRelacionMipresSispro (payload) {
      return _MipresService.actualizarRelacionMipresSispro(payload).then(({ data }) => {
        console.log(data)
        return data
      })
    },
    paginarRelacionCodmxCodmipres (params) {
      return _MipresService.paginarRelacionCodmxCodmipres(params).then(({ data }) => {
        return data
      })
    },
    paginarRelacionMipresSispro (params) {
      return _MipresService.paginarRelacionMipresSispro(params).then(({ data }) => {
        return data
      })
    }
  }
})
