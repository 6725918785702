
import { defineStore } from 'pinia'
import MotivosAutorizacionService from '../services/motivos_autorizacion.service'
const _MotivosAutorizacionService = new MotivosAutorizacionService()

export const useMotivosAutorizacionStore = defineStore('motivosAutorizacionStore', {
  state: () => ({
    listado_motivos_autorizacion: [],
    motivo_autorizacion_seleccionado: 0
  }),
  getters: {
    _listado_motivos_autorizacion: state => state.listado_motivos_autorizacion,
    _motivo_autorizacion_seleccionado: state => state.motivo_autorizacion_seleccionado
  },
  actions: {
    obtenerListadoMotivosAutorizacion (cliente) {
      return _MotivosAutorizacionService.listarMotivosCliente(cliente).then(({ data }) => {
        this.listado_motivos_autorizacion = data
        return data
      })
    }
  }
})
