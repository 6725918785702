
import { defineStore } from 'pinia'
import { usePacientesStore } from './pacientes.store'
import { useMedicamentosStore } from './medicamentos.store'
import { useMotivosAutorizacionStore } from './motivos_autorizacion.store'
import { useBodegasStore } from './bodegas.store'
import { useCalculoCuotaStore } from './calculo_cuota.store'
import { useDiagnosticosStore } from './diagnosticos.store'
import { useConfigGlobal } from './config-ov.store'
import { useRegimenStore } from './regimen.store'
import { useMipresStore } from './mipres.store'
import { entregasStore } from './entregas.store'
import SyncPagosService from '../services/sync-pagos'
import OrdenService from '../services/orden.service'
import SeguimientoEntregasService from '../services/seguimiento_entregas.service'
import DuracionTratamientoService from '../services/duracion_tratamiento.service'
import FrecuenciasService from '../services/frecuencias.service'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
const _OrdenService = new OrdenService()
const _FrecuenciasService = new FrecuenciasService()
const _SyncPagosService = new SyncPagosService()
const _DuracionTratamientoService = new DuracionTratamientoService()
const _SeguimientoEntregasService = new SeguimientoEntregasService()
const medicamentosStore = useMedicamentosStore()
const motivosAutorizacionStore = useMotivosAutorizacionStore()
const pacientesStore = usePacientesStore()
const bodegasStore = useBodegasStore()
const entregas = entregasStore()
const calculoCuotaStore = useCalculoCuotaStore()
const diagnosticosStore = useDiagnosticosStore()
const regimenStore = useRegimenStore()
const configGlobal = useConfigGlobal()
const mipresStore = useMipresStore()

export const useOrdenesStore = defineStore('ordenesStore', {
  state: () => ({
    listado_frecuencias: [],
    listado_seguimientos_entrega: [],
    listado_duraciones_tratamiento: [],
    activar_select_frecuencias: false,
    historial_documento: [],
    bodega_ppal_diferente: false,
    bodega_ppal_usuario: '',
    creando_orden: false,
    header: {
      fechaCont: dayjs().format('YYYY-MM-DD'),
      cliente: '',
      bodega: ''
    },
    udf: {
      regimen: 0,
      mot_autoriza: '',
      portabilidad: 0,
      localizacion: 0,
      zonificacion: '',
      diagPpal: '',
      diagRel: '',
      ex_cuota: '02',
      ex_pac: '',
      cod_mpio: 0,
      porcentaje_cuota: 0,
      cuota_moderadora: 0
    },
    footer: {
      comentarios: '',
      exonera_cuota: '02',
      total_cuota_recuperacion: 0,
      total_cuota_moderadora: 0
    },
    limpiar_orden: false,
    orden: {
      Cliente: {},
      Bodega: {},
      DocumentLines: [],
      Paciente: {},
      MotAuto: {},
      DiagPpal: {
        CdCie: '',
        DesCie: ''
      },
      DiagSecu: {
        CdCie: '',
        DesCie: ''
      }
    },
    medicamentos_originales_find: [],
    ultima_orden: 0,
    mostrar_calcular_cuota: true,
    pago_sincronizado_sap: false
  }),
  getters: {
    _listado_frecuencias: state => state.listado_frecuencias,
    _bodega_ppal_diferente: state => state.bodega_ppal_diferente,
    _bodega_ppal_usuario: state => state.bodega_ppal_usuario,
    _listado_seguimientos_entrega: state => state.listado_seguimientos_entrega,
    _listado_duraciones_tratamiento: state => state.listado_duraciones_tratamiento,
    _activar_select_frecuencias: state => state.activar_select_frecuencias,
    _creando_orden: state => state.creando_orden,
    _header: state => state.header,
    _udf: state => state.udf,
    _limpiar_orden: state => state.limpiar_orden,
    _footer: state => state.footer,
    _orden: state => state.orden,
    _ultima_orden: state => state.ultima_orden,
    _medicamentos_originales_find: state => state.medicamentos_originales_find,
    _mostrar_calcular_cuota: state => state.mostrar_calcular_cuota,
    _historial_documento: state => state.historial_documento,
    _pago_sincronizado_sap: state => state.pago_sincronizado_sap
  },
  actions: {
    crearOrdenVenta () {
      this.limpiar_orden = false
      this.creando_orden = true
      if (!this.header.cliente || !this.udf.regimen || !pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf || !this.header.fechaCont || !this.header.bodega || !this.udf.diagPpal || !this.udf.diagRel) {
        console.log(
          'cliente->', this.header.cliente ? this.header.cliente : 'FALTA',
          'identificación->', pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf ? pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf : 'FALTA',
          'fecha de contabilidad->', this.header.fechaCont ? this.header.fechaCont : 'FALTA',
          'bodega->', this.header.bodega ? this.header.bodega : 'FALTA',
          'diagnotico principal->', this.udf.diagPpal ? this.udf.diagPpal : 'FALTA',
          'diagnostico relacionado->', this.udf.diagRel ? this.udf.diagRel : 'FALTA',
          'regimen->', this.udf.regimen
        )
        return Swal.fire('Error', 'Debes completar todos los campos para continuar', 'error').then(() => false)
        // return false
      } else {
        const medicamentos = []
        const camposFaltantes = []
        const columnasTabla = configGlobal._columnas_tabla
        if (!medicamentosStore._medicamentos_tabla) return Swal.fire('Error', 'Debes ingresar minimo un medicamento para continuar', 'error').then(() => false)
        // Validación las culumnas requeridas por medicamento
        for (const i of columnasTabla) {
          const objeto = {}
          var columna = i.ColName
          var requerido = i.IsRequired
          medicamentosStore._medicamentos_tabla.map((t, idx) => {
            var valor = t[columna]
            objeto[columna] = valor
            // Validación si el medicamento es alto costo Fecha de Autorización queda obligatorio
            if (t.U_PHR_Nivel > 3 && columna === 'U_PHR_FecAutoriza') {
              requerido = true
            }
            if (requerido && !valor) {
              camposFaltantes.push(objeto)
            }
          })
        }
        if (!camposFaltantes.length) {
          for (const i of medicamentosStore._medicamentos_tabla) {
            const objeto = {}
            for (const j of columnasTabla) {
              objeto[j.ColName] = i[j.ColName]
            }
            objeto.LineNum = medicamentos.length
            objeto.LineStatus = i.OpenCreQty ? 0 : 1
            objeto.apartados = i.apartados
            // Validacion para los stock de movimientos que tengan quentity en 0
            objeto.StockMovements = i.StockMovements.length ? [...i.StockMovements.filter(a => a.Quantity !== 0)] : []
            if (objeto.StockMovements.length) {
              objeto.StockMovements.map(a => {
                a.Quantity = Math.abs(a.Quantity) * (-1)
                return a
              })
            }
            if (objeto.U_PHR_RegMed) {
              objeto.U_PHR_RegMed = objeto.U_PHR_RegMed.RegMedico
            }
            if (objeto.U_PHR_IPSPrest) {
              objeto.U_PHR_IPSPrest = objeto.U_PHR_IPSPrest.Id
            }
            medicamentos.push(objeto)
          }
          for (const i of medicamentos) {
            i.U_PHR_CtoAsociado = this.udf.mot_autoriza.Codigo === '04' || this.udf.mot_autoriza.Codigo === '07' ? 'CAPITA' : (this.udf.mot_autoriza.Codigo === '08' || this.udf.mot_autoriza.Codigo === '09' ? 'PAGO PROSPECTIVO' : 'EVENTO')
            i.CostingCode = bodegasStore._bodega_seleccionada.U_PHR_CentroCosto
          }
          const orden = {
            CardCode: this.header.cliente.CardCode,
            ClienteId: this._header.cliente.Id,
            MotAutorizaId: this._udf.mot_autoriza.Id,
            DocDate: this.header.fechaCont,
            TaxDate: this.header.fechaCont,
            FechaContabilizacion: this.header.fechaCont,
            FechaDocumento: this.header.fechaCont,
            Comments: this.footer.comentarios,
            U_PHR_Bodega: this.header.bodega,
            U_PHR_NumDcto: pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf,
            U_PHR_RangSalarial: pacientesStore._paciente_seleccionado.U_ACS_Nivel,
            U_PHR_Exorera: this.udf.ex_pac,
            U_PHR_MotExon: null,
            U_PHR_AutoEntrega: 'S',
            U_PHR_AutPendiente: 'N',
            U_PHR_PagaPhr: 'N',
            U_PHR_Autorizacion: 'N',
            U_PHR_MotAutoriza: this.udf.mot_autoriza.Codigo,
            U_PHR_DiagnosPpal: this.udf.diagPpal,
            U_PHR_DiagnosSecu: this.udf.diagRel,
            U_PHR_NumTurno: null,
            U_PHR_NumPagoReci: null,
            U_PHR_PorCuota: this.udf.porcentaje_cuota,
            U_PHR_SumaHomologo: null,
            U_PHR_SumaCR: this.footer.total_cuota_recuperacion,
            U_PHR_ExModera: this.footer.exonera_cuota,
            U_PHR_CtMod: this.udf.ex_cuota === '01' ? this.udf.cuota_moderadora : 0,
            U_PHR_RegPac: this.udf.regimen,
            U_PHR_Zonificacion: this.udf.zonificacion,
            U_PHR_Portabilidad: this.udf.portabilidad,
            U_PHR_CodMunicipio: this.udf.cod_mpio,
            U_PHR_ModContrato: this.udf.mot_autoriza.CodModContrato,
            DocumentLines: medicamentos,
            U_ACS_TpoIdentf: pacientesStore._paciente_seleccionado.U_ACS_TpoIdentf,
            U_PHR_Zonificacion_Port: this.udf.portabilidad === 1 ? this.udf.localizacion.CodSap : null
          }
          return _OrdenService.crearOrden(orden).then((data) => {
            return data
          }).catch((err) => {
            console.log('entro por el err------------>', err)
          })
        } else {
          console.log('estos son los campos faltantes-->', camposFaltantes)
          return Swal.fire('Error', 'Debes ingresar los campos faltantes para continuar', 'error').then(() => false)
        }
      }
    },
    async actualizarOrdenVenta (id) {
      this.limpiar_orden = false
      this.creando_orden = true
      if (!this.header.cliente || !pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf || !this.header.fechaCont || !this.header.bodega || !this._udf.diagPpal || !this._udf.diagRel) {
        console.log(
          'cliente->', this.header.cliente ? this.header.cliente : 'FALTA',
          'identificación->', pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf ? pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf : 'FALTA',
          'fecha de contabilidad->', this.header.fechaCont ? this.header.fechaCont : 'FALTA',
          'bodega->', this.header.bodega ? this.header.bodega : 'FALTA',
          'diagnotico principal->', this.udf.diagPpal ? this.udf.diagPpal : 'FALTA',
          'diagnostico relacionado->', this.udf.diagRel ? this.udf.diagRel : 'FALTA'
        )
        return Swal.fire('Error', 'Debes completar todos los campos para continuar', 'error').then(() => false)
      } else {
        const medicamentos = []
        const camposFaltantes = []
        const columnasTabla = configGlobal._columnas_tabla
        const medicamentosTabla = medicamentosStore._medicamentos_tabla.filter(a => !a.por_entregar)
        if (!medicamentosTabla) return Swal.fire('Error', 'Debes ingresar minimo un medicamento para continuar', 'error').then(() => false)
        // Validación las culumnas requeridas por medicamento
        for (const i of columnasTabla) {
          const objeto = {}
          var columna = i.ColName
          var requerido = i.IsRequired
          medicamentosStore._medicamentos_tabla.map((t, idx) => {
            var valor = t[columna]
            objeto[columna] = valor
            // Validación si el medicamento es alto costo Fecha de Autorización queda obligatorio
            if (t.U_PHR_Nivel > 3 && columna === 'U_PHR_FecAutoriza') {
              requerido = true
            }
            if (requerido && !valor) {
              camposFaltantes.push(objeto)
            }
          })
        }
        if (!camposFaltantes.length) {
          const _suma = (array) => {
            let suma = 0
            array.map(q => {
              suma += q.Quantity
            })
            return suma
          }
          for (const i of medicamentosStore._medicamentos_tabla) {
            const objeto = {}
            for (const j of columnasTabla) {
              objeto[j.ColName] = i[j.ColName]
            }
            objeto.LineNum = medicamentos.length
            objeto.LineStatus = i.OpenCreQty ? 0 : 1
            objeto.apartados = i.apartados
            // Validacion para los stock de movimientos que tengan quentity en 0
            objeto.StockMovements = i.StockMovements.length ? [...i.StockMovements.filter(a => a.Quantity !== 0)] : []
            if (objeto.StockMovements.length) {
              const lotesUnificados = []
              objeto.StockMovements.map(a => {
                if (objeto.StockMovements.filter(j => j.BatchNumber === a.BatchNumber && j.WhsCode === a.WhsCode).length > 1) {
                  if (!lotesUnificados.some(r => r.BatchNumber === a.BatchNumber)) {
                    lotesUnificados.push({ ...a, Quantity: _suma(objeto.StockMovements.filter(j => j.BatchNumber === a.BatchNumber)) })
                  }
                } else {
                  lotesUnificados.push(a)
                }
              })
              objeto.StockMovements = lotesUnificados
              objeto.StockMovements.map(a => {
                a.Quantity = Math.abs(a.Quantity) * (-1)
                return a
              })
            }
            if (objeto.U_PHR_RegMed) {
              objeto.U_PHR_RegMed = objeto.U_PHR_RegMed.RegMedico
            }
            if (objeto.U_PHR_IPSPrest) {
              objeto.U_PHR_IPSPrest = objeto.U_PHR_IPSPrest.Id
            }
            objeto.Id = i.id_ovdt
            medicamentos.push(objeto)
          }
          for (const i of medicamentos) {
            i.U_PHR_CtoAsociado = this.udf.mot_autoriza.Codigo === '04' || this.udf.mot_autoriza.Codigo === '07' ? 'CAPITA' : (this.udf.mot_autoriza.Codigo === '08' || this.udf.mot_autoriza.Codigo === '09' ? 'PAGO PROSPECTIVO' : 'EVENTO')
            i.CostingCode = bodegasStore._bodega_seleccionada.U_PHR_CentroCosto
          }
          const orden = {
            CardCode: this.header.cliente.CardCode,
            ClienteId: this._header.cliente.Id,
            MotAutorizaId: this._udf.mot_autoriza.Id,
            DocDate: this.header.fechaCont,
            TaxDate: this.header.fechaCont,
            FechaContabilizacion: this.header.fechaCont,
            FechaDocumento: this.header.fechaCont,
            Comments: this.footer.comentarios,
            U_PHR_Bodega: this.header.bodega,
            U_PHR_NumDcto: pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf,
            U_PHR_RangSalarial: pacientesStore._paciente_seleccionado.U_ACS_Nivel,
            U_PHR_Exorera: this.udf.ex_pac,
            U_PHR_MotExon: null,
            U_PHR_AutoEntrega: 'S',
            U_PHR_AutPendiente: 'N',
            U_PHR_PagaPhr: 'N',
            U_PHR_Autorizacion: 'N',
            U_PHR_MotAutoriza: this.udf.mot_autoriza.Codigo,
            U_PHR_DiagnosPpal: this.udf.diagPpal,
            U_PHR_DiagnosSecu: this.udf.diagRel,
            U_PHR_NumTurno: null,
            U_PHR_NumPagoReci: null,
            U_PHR_PorCuota: this.udf.porcentaje_cuota,
            U_PHR_SumaHomologo: null,
            U_PHR_SumaCR: this.footer.total_cuota_recuperacion,
            U_PHR_ExModera: this.footer.exonera_cuota,
            U_PHR_CtMod: this.udf.ex_cuota === '01' ? this.udf.cuota_moderadora : 0,
            U_PHR_RegPac: this.udf.regimen,
            U_PHR_Zonificacion: this.udf.zonificacion,
            U_PHR_Portabilidad: this.udf.portabilidad,
            U_PHR_CodMunicipio: this.udf.cod_mpio,
            U_PHR_ModContrato: this.udf.mot_autoriza.CodModContrato,
            DocumentLines: medicamentos,
            U_ACS_TpoIdentf: pacientesStore._paciente_seleccionado.U_ACS_TpoIdentf,
            U_PHR_Zonificacion_Port: this.udf.portabilidad === 1 ? this.udf.localizacion.CodSap : null
          }
          return _OrdenService.actualizarOrden(orden, id).then(async ({ data }) => {
            return data
          })
        } else {
          console.log('estos son los campos faltantes-->', camposFaltantes)
          return Swal.fire('Error', 'Debes ingresar los campos faltantes para continuar', 'error').then(() => false)
        }
      }
    },
    async actualizarOvDigitacion (id) {
      if (!this._udf.diagPpal || !this._udf.diagRel) {
        console.log(
          'diagnotico principal->', this.udf.diagPpal ? this.udf.diagPpal : 'FALTA',
          'diagnostico relacionado->', this.udf.diagRel ? this.udf.diagRel : 'FALTA'
        )
        return Swal.fire('Error', 'Debes completar todos los campos para continuar', 'error').then(() => false)
      } else {
        const medicamentos = []
        const camposFaltantes = []
        const columnasTabla = configGlobal._columnas_tabla
        const medicamentosTabla = medicamentosStore._medicamentos_tabla.filter(a => !a.por_entregar)
        if (!medicamentosTabla) return Swal.fire('Error', 'Debes ingresar minimo un medicamento para continuar', 'error').then(() => false)
        // Validación las culumnas requeridas por medicamento
        for (const i of columnasTabla) {
          const objeto = {}
          var columna = i.ColName
          var requerido = i.IsRequired
          medicamentosStore._medicamentos_tabla.map((t, idx) => {
            var valor = t[columna]
            objeto[columna] = valor
            // Validación si el medicamento es alto costo Fecha de Autorización queda obligatorio
            if (t.U_PHR_Nivel > 3 && columna === 'U_PHR_FecAutoriza') {
              requerido = true
            }
            if (requerido && !valor) {
              camposFaltantes.push(objeto)
            }
          })
        }
        if (!camposFaltantes.length) {
          for (const i of medicamentosStore._medicamentos_tabla) {
            const objeto = {}
            for (const j of columnasTabla) {
              objeto[j.ColName] = i[j.ColName]
            }
            if (objeto.U_PHR_RegMed) {
              objeto.U_PHR_RegMed = objeto.U_PHR_RegMed.RegMedico
            }
            if (objeto.U_PHR_IPSPrest) {
              objeto.U_PHR_IPSPrest = objeto.U_PHR_IPSPrest.Id
            }
            objeto.Id = i.id_ovdt
            medicamentos.push(objeto)
          }
          for (const i of medicamentos) {
            delete i.DelivrdQty
            delete i.OpenCreQty
            if (i.ItemName) delete i.ItemName
            if (i.Quantity) delete i.Quantity
            if (i.WarehouseCode) delete i.WarehouseCode
          }
          const orden = {
            U_PHR_DiagnosPpal: this.udf.diagPpal,
            U_PHR_DiagnosSecu: this.udf.diagRel,
            DocumentLines: medicamentos
          }
          return _OrdenService.actualizarOvDigitacion(id, orden).then(({ data }) => {
            return data
          })
        } else {
          console.log('estos son los campos faltantes-->', camposFaltantes)
          return Swal.fire('Error', 'Debes ingresar los campos faltantes para continuar', 'error').then(() => false)
        }
      }
    },
    listarFrecuencias () {
      _FrecuenciasService.listarFrecuencias().then(({ data }) => {
        this.listado_frecuencias = data
      })
    },
    find (id) {
      return _OrdenService.find(id).then(async ({ data }) => {
        this.orden = data
        this.header.cliente = {
          CardCode: data.CardCode,
          ...data.Cliente,
          name: data.CardCode + ' - ' + data.Cliente.CardName
        }
        this.header.fechaCont = data.FechaDocumento
        this.header.bodega = data.Bodega.WhsCode
        this.udf.diagPpal = data.U_PHR_DiagnosPpal
        this.udf.diagRel = data.U_PHR_DiagnosSecu
        this.udf.cod_mpio = data.U_PHR_CodMunicipio
        if (data.BodegaPrincipalUsuario) {
          this.bodega_ppal_usuario = data.BodegaPrincipalUsuario.idBodega
          this.bodega_ppal_diferente = data.BodegaPrincipalUsuario.idBodega !== data.U_PHR_Bodega
        }
        bodegasStore.bodega_seleccionada = {
          ...data.Bodega,
          name: data.Bodega.WhsCode + ' - ' + data.Bodega.WhsName
        }
        pacientesStore.paciente_seleccionado = {
          name: data.Paciente.U_ACS_NmrIdentf + ' - ' + data.Paciente.U_ACS_NmbPct,
          rango_nivel: data.Paciente.U_ACS_Regimen + ' ' + data.Paciente.U_ACS_Nivel,
          Zonificacion: data.Paciente.Localizacion.nomDpto + ', ' + data.Paciente.Localizacion.nomMpio,
          ...data.Paciente
        }
        pacientesStore.tipo_id_seleccionado = data.Paciente.U_ACS_TpoIdentf
        motivosAutorizacionStore.obtenerListadoMotivosAutorizacion({ ClienteId: data.Cliente.Id }).then(() => {
          configGlobal.obtenerColumnasTabla({ cardCode: this._header.cliente.CardCode, motAutoriza: this._udf.mot_autoriza.Codigo })
        })
        diagnosticosStore.diagnostico_ppal = {
          ...data.DiagPpal,
          name: data.DiagPpal.CdCie + ' - ' + data.DiagPpal.DesCie
        }
        diagnosticosStore.diagnostico_relacionado = {
          ...data.DiagSecu,
          name: data.DiagSecu.CdCie + ' - ' + data.DiagSecu.DesCie
        }
        this.udf.mot_autoriza = data.MotAut
        if (data.U_PHR_MotAutoriza === '05' || data.U_PHR_MotAutoriza === '06') {
          motivosAutorizacionStore.listado_motivos_autorizacion = motivosAutorizacionStore._listado_motivos_autorizacion.filter(a => a.Codigo === '05' || a.Codigo === '06')
        }
        regimenStore.$patch((state) => {
          const regimenPrincipal = regimenStore._listado_regimen.find(a => a.nombre.toLowerCase() === pacientesStore._paciente_seleccionado.U_ACS_Regimen.toLowerCase())
          const array = [regimenPrincipal, ...regimenStore._listado_regimen.filter(a => a.PadreId !== regimenPrincipal.id && a.PadreId)]
          state.regimen_por_paciente = array
        })
        this.udf.regimen = data.U_PHR_RegPac
        this.udf.localizacion = {
          ...data.ZonificacionPort,
          Zonificacion: data.ZonificacionPort ? (data.ZonificacionPort.nomDpto + ', ' + data.ZonificacionPort.nomMpio) : ''
        }
        this.udf.portabilidad = parseInt(data.U_PHR_Portabilidad)
        if (data.DocumentLines.some(e => e.ItemCode === 'CT_0002')) {
          this.udf.cuota_moderadora = data.DocumentLines.find(e => e.ItemCode === 'CT_0002').UnitPrice
          this.footer.total_cuota_moderadora = data.DocumentLines.find(e => e.ItemCode === 'CT_0002').UnitPrice
        }
        this.footer.comentarios = data.Comments
        this.mostrar_calcular_cuota = false
        if (data.U_PHR_MotAutoriza === '05' || data.U_PHR_MotAutoriza === '06') {
          await mipresStore.obtenerCodigos({ U_ACS_TpoIdentf: data.U_ACS_TpoIdentf, U_ACS_NmrIdentf: data.U_PHR_NumDcto })
        }
        mipresStore.listado_codigos = []
        for (const i of data.DocumentLines) {
          if (!i.ItemCode.includes('CT_')) {
            if (i.Mipres) {
              mipresStore.listado_codigos.push({
                fecmaxent: i.Mipres.fecmaxent,
                iddireccionamiento: i.Mipres.iddireccionamiento,
                noentrega: i.Mipres.noentrega,
                noprescripcion: i.Mipres.noprescripcion
              })
            }
            const unidades = [{
              value: +i.Medicamento.NumInSale,
              description: i.Medicamento.SalUnitMsr,
              code: +i.Medicamento.NumInSale === 1 ? 'tYes' : 'tNo'
            }]
            if (+i.Medicamento.NumInSale > 1 && i.Medicamento.InvntryUom) {
              unidades.push({
                value: +i.Medicamento.SalPackUn,
                description: i.Medicamento.InvntryUom,
                code: 'tYes'
              })
            }
            mipresStore.validarNumerosDireccionamiento(i.U_PHR_CodMipres)
            const lotes = []
            let sumaStock = 0
            if (i.StockMovements.length) {
              for (const j of i.StockMovements) {
                lotes.push({
                  BatchNumber: j.BatchNumber,
                  FecVcto: j.Lote.FecVcto,
                  Quantity: Math.abs(j.Quantity),
                  cantstock: j.Lote.cantstock,
                  ItemCode: j.ItemCode,
                  WhsCode: j.WhsCode,
                  ObjectType: j.ObjectType,
                  BodegaNombre: j.Bodega ? j.Bodega.WhsName : ''
                })
                sumaStock += j.Lote.cantstock
              }
            }
            medicamentosStore.medicamentos_tabla.push({
              id_ovdt: i.Id,
              ItemCode: i.ItemCode,
              ItemName: i.Medicamento.ItemName,
              ArticuloId: i.ArticuloId,
              GrpCode: i.Medicamento.AgrupId,
              UseBaseUnits: i.UseBaseUnits,
              UnitsOfMeasurment: +i.UnitsOfMeasurment,
              WarehouseCode: i.WarehouseCode,
              U_PHR_CtoAsociado: i.U_PHR_CtoAsociado,
              UnitPrice: i.UnitPrice,
              U_PHR_SeguiEntrega: i.U_PHR_SeguiEntrega,
              U_PHR_NumEntregas: +i.U_PHR_NumEntregas,
              U_PHR_Exonerado: i.U_PHR_Exonerado,
              U_PHR_CuotaRecupe: i.U_PHR_CuotaRecupe,
              U_PHR_CtdPrescrita: +i.U_PHR_CtdPrescrita,
              U_PHR_FecPrescrip: i.U_PHR_FecPrescrip,
              U_PHR_Frecuencia: i.U_PHR_Frecuencia,
              U_PHR_DuraTratami: i.U_PHR_DuraTratami,
              U_PHR_CdHomologo: i.U_PHR_CdHomologo,
              U_PHR_NomHomologo: i.U_PHR_NomHomologo,
              U_PHR_CntHomologo: i.U_PHR_CntHomologo,
              U_PHR_PrHomologo: i.U_PHR_PrHomologo,
              U_PHR_TotalHomologo: i.U_PHR_TotalHomologo,
              U_PHR_NumAutoriza: +i.U_PHR_NumAutoriza,
              U_PHR_FecAutoriza: i.U_PHR_FecAutoriza,
              U_PHR_NoAcCTC: i.U_PHR_NoAcCTC,
              U_PHR_FchAcCTC: i.U_PHR_FchAcCTC,
              U_PHR_FchSolActCTC: i.U_PHR_FchSolActCTC,
              U_PHR_CodMipres: i.U_PHR_CodMipres,
              U_PHR_NumDirec: i.U_PHR_NumDirec,
              U_PHR_FecPres: i.U_PHR_FecPres,
              U_PHR_JunMedi: i.U_PHR_JunMedi,
              U_PHR_Siniestro: i.U_PHR_Siniestro,
              CostingCode: i.CostingCode,
              U_PHR_Sta_Pen: i.U_PHR_Sta_Pen,
              FreeText: i.FreeText,
              U_PHR_IdPrincipal: i.U_PHR_IdPrincipal,
              LineNum: +i.LineNum,
              LineStatus: i.LineStatus,
              Frecuencia: i.Frecuencia ? i.Frecuencia.Name : '',
              DurTrata: i.DurTrata ? i.DurTrata.Name : '',
              SegEnt: i.SegEnt ? i.SegEnt.Name : '',
              U_PHR_RegMed: i.Medico ? {
                ...i.Medico,
                name: i.Medico.RegMedico + ' - ' + i.Medico.NomMedico
              } : '',
              U_PHR_IPSPrest: i.Ips,
              DelivrdQty: i.DelivrdQty,
              OpenCreQty: i.OpenCreQty,
              cantidad_pendiente_inicial: i.OpenCreQty,
              Quantity: i.Quantity,
              unidades_medida: unidades,
              unidadSeleccionada: unidades.find(e => e.code === i.UseBaseUnits),
              name: '(Cant: ' + sumaStock + ') - ' + i.ItemCode + ' - ' + i.Medicamento.ItemName,
              StockMovements: lotes,
              StockTotal: i.Medicamento.StockTotal,
              StockDisponible: i.Medicamento.StockDisponible,
              apartados: [],
              OnHandQty: sumaStock,
              ClosedAt: i.ClosedAt,
              Mipres: i.Mipres || null
            })
          }
        }
        return data
      })
    },
    getUltimaOrden () {
      _OrdenService.obtenerUltimaOrden().then(({ data }) => {
        this.ultima_orden = data.Id + 1
      })
    },
    cancelarOrden (id) {
      return _OrdenService.cancelarOrden(id).then((data) => {
        return data
      })
    },
    resetData () {
      return new Promise((resolve, reject) => {
        const resetear = () => {
          configGlobal.columnas_tabla = configGlobal._columnas_tabla_inicial
          this.creando_orden = false
          this.header = {
            fechaCont: dayjs().format('YYYY-MM-DD'),
            cliente: '',
            bodega: ''
          }
          this.udf = {
            regimen: 0,
            mot_autoriza: '',
            portabilidad: 0,
            localizacion: 0,
            zonificacion: '',
            diagPpal: '',
            diagRel: '',
            ex_cuota: '02',
            ex_pac: '',
            cod_mpio: 0,
            porcentaje_cuota: 0,
            cuota_moderadora: 0
          }
          this.footer = {
            comentarios: '',
            exonera_cuota: '02',
            total_cuota_recuperacion: 0,
            total_cuota_moderadora: 0
          }
          this.limpiar_orden = true
          this.orden = {
            Cliente: {},
            Bodega: {},
            DocumentLines: [],
            Paciente: {},
            MotAuto: {},
            DiagPpal: {
              CdCie: '',
              DesCie: ''
            },
            DiagSecu: {
              CdCie: '',
              DesCie: ''
            }
          }
          this.pago_sincronizado_sap = false
          this.medicamentos_originales_find = []
          medicamentosStore.$patch((state) => {
            state.listado_medicamentos = []
            state.medicamentos_tabla = []
            state.info_medicamento = {}
          })
          motivosAutorizacionStore.$patch((state) => {
            state.listado_motivos_autorizacion = []
            state.motivo_autorizacion_seleccionado = 0
          })
          entregas.$patch((state) => {
            state._orden = {
              Cliente: {},
              Bodega: {},
              DocumentLines: [],
              Paciente: {},
              MotAuto: {},
              DiagPpal: {
                CdCie: '',
                DesCie: ''
              },
              DiagSecu: {
                CdCie: '',
                DesCie: ''
              }
            }
            state._medicamentos_eliminados = []
            state._medicamentos_entregar = []
          })
          calculoCuotaStore.$patch((state) => {
            state.cuota_calculada = 0
            state.valor_max_cobro = 0
          })
          bodegasStore.$patch((state) => {
            state.bodega_seleccionada = ''
          })
          pacientesStore.$patch((state) => {
            state.listado_pacientes = []
            state.paciente_seleccionado = ''
            state.historia_clinica = []
          })
          diagnosticosStore.diagnostico_ppal = ''
          diagnosticosStore.diagnostico_relacionado = ''
        }
        resolve(resetear())
      })
    },
    eliminarLineaOvdt (id) {
      return _OrdenService.eliminarLineaOrden(id).then(({ data }) => {
        return data
      })
    },
    cierreLineaFalso (payload) {
      return _OrdenService.cierreLineaFalso(payload).then(({ data }) => {
        return data
      })
    },
    reabrirLinea (payload) {
      return _OrdenService.reabrirLinea(payload).then(({ data }) => {
        return data
      })
    },
    getHistorialOv (ov) {
      _OrdenService.findlogsByOv(ov).then(({ data }) => {
        const listado = []
        if (data.length) {
          for (const i of data) {
            if (listado.length && listado.some(a => a.hora === i.createdAt)) {
              listado.find(a => a.hora === i.createdAt).registros.push({
                ...i,
                afterTextJson: JSON.parse(i.afterTextJson),
                beforeTextJson: i.beforeTextJson ? JSON.parse(i.beforeTextJson) : null
              })
            } else {
              listado.push({
                hora: i.createdAt,
                registros: [
                  {
                    ...i,
                    afterTextJson: JSON.parse(i.afterTextJson),
                    beforeTextJson: i.beforeTextJson ? JSON.parse(i.beforeTextJson) : null
                  }
                ]
              })
            }
          }
        }
        this.state = listado
      })
    },
    reemplazarMedicamento (id, payload) {
      return _OrdenService.reemplazarMedicamento(id, payload).then(({ data }) => {
        return data
      })
    },
    findPagoRecibido (ov) {
      _SyncPagosService.find(ov).then(({ data }) => {
        data ? this.pago_sincronizado_sap = data.Status === 2 : this.pago_sincronizado_sap = false
      })
    },
    listarSeguimientoEntregas () {
      _SeguimientoEntregasService.listarSeguimientoEntregas().then(({ data }) => {
        this.listado_seguimientos_entrega = data
      })
    },
    listarDuracionesTratamiento () {
      _DuracionTratamientoService.listarDuracionTratamiento().then(({ data }) => {
        this.listado_duraciones_tratamiento = data
      })
    },
    validacionOvSincronizadaSap (id) {
      return _OrdenService.validarOvSync(id).then((data) => {
        if (data) {
          return data
        } else {
          _SyncPagosService.find(id).then(({ data }) => {
            data ? this.pago_sincronizado_sap = data.Status === 2 : this.pago_sincronizado_sap = false
          })
        }
      })
    },
    obtenerBodegaPpalUsuario () {
      return _OrdenService.findBodegaPpalUsuario().then(({ data }) => {
        this.bodega_ppal_usuario = data
        return data
      })
    },
    validarPorcentajeCuota () {
      if (typeof pacientesStore._paciente_seleccionado === 'object') {
        if (this._udf.mot_autoriza.Codigo === '06' || this._udf.mot_autoriza.Codigo === '05') {
          if (pacientesStore._paciente_seleccionado.ValorCuota) {
            this._udf.porcentaje_cuota = 0
            this._udf.cuota_moderadora = pacientesStore._paciente_seleccionado.ValorCuota
          } else {
            this._udf.cuota_moderadora = 0
            this._udf.porcentaje_cuota = pacientesStore._paciente_seleccionado.ValorPorcentaje
          }
        } else {
          this._udf.porcentaje_cuota = 0
          this._udf.cuota_moderadora = pacientesStore._paciente_seleccionado.ValorCuota
        }
      } else {
        this._udf.porcentaje_cuota = 0
        this._udf.cuota_moderadora = 0
      }
    }
  }
})
