import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class RegimenService {
  listarRegimen () {
    return http.get(`${baseUrl}/regimen/listar-regimen`, {
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/regimen/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getReg (id) {
    return http.get(`${baseUrl}/regimen/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  crearReg (data) {
    return http.post(`${baseUrl}/regimen`, data, {
      headers: {
        loading: true
      }
    })
  }

  editarReg (data) {
    return http.put(`${baseUrl}/regimen/${data.id}`, data, {
      headers: {
        loading: true
      }
    })
  }

  eliminarReg (id) {
    return http.delete(`${baseUrl}/regimen/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
