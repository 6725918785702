import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class MipresService {
  listarCodigos (params) {
    return http.get(`${baseUrl}/mipres-direccionamiento/search-by-patient`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  actualizarRelacionMipresCodMxCodMipres (payload) {
    return http.post(`${baseUrl}/mipres-direccionamiento/actualizar-relacion-mipres-codmx-codmipres`, payload, {
      headers: {
        loading: true
      }
    })
  }

  actualizarRelacionMipresSispro (payload) {
    return http.post(`${baseUrl}/mipres-direccionamiento/actualizar-relacion-mipres-sispro`, payload, {
      headers: {
        loading: true
      }
    })
  }

  paginarRelacionCodmxCodmipres (params) {
    return http.get(`${baseUrl}/mipres-direccionamiento/paginar-relacion-mipres-codmx-codmipres`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  paginarRelacionMipresSispro (params) {
    return http.get(`${baseUrl}/mipres-direccionamiento/paginar-relacion-mipres-sispro`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
