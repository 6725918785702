import http from '@/libs/http'
import axios from 'axios'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CARTA_AGOTADO_COMPRAS

export default class CartaAgotadoService {
  obtenerCartaPorMedicamento (params) {
    return http.get(`${baseUrl}/carta/findCartaByMd`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  downloadPdf (id) {
    const url = `${baseUrl}/download/downloadCarta/${id}`
    const res = axios.getUri({ url })
    window.open(res, '_blank')
  }
}
