import { defineStore } from 'pinia'
import MedicamentosService from '../services/medicamentos.service'
import CartaAgotadoService from '../services/carta-agotado.service'
const _MedicamentosService = new MedicamentosService()
const _CartaAgotadoService = new CartaAgotadoService()

export const useMedicamentosStore = defineStore('medicamentosStore', {
  state: () => ({
    listado_medicamentos: [],
    medicamentos_tabla: [],
    info_medicamento: {},
    medicamentos_apartados: [],
    display_ver_medicamento: false
  }),
  getters: {
    _listado_medicamentos: state => state.listado_medicamentos,
    _medicamentos_tabla: state => state.medicamentos_tabla,
    _info_medicamento: state => state.info_medicamento,
    _medicamentos_apartados: state => state.medicamentos_apartados,
    _display_ver_medicamento: state => state.display_ver_medicamento
  },
  actions: {
    getListadoMedicamentos (params, name) {
      if (params.editar) {
        return _MedicamentosService.listarMedicamentos(params, name).then(({ data }) => {
          let listado = []
          if (data.length) {
            listado = data.filter(a => a.cantstock > 0)
            for (const i of data.filter(a => !a.cantstock)) {
              if (!listado.some(a => a.CodMx === i.CodMx)) {
                listado.push(i)
              }
            }
          }
          const array = []
          for (const i of listado) {
            let nombre = '(Cant: ' + i.cantstock + ') - ' + i.CodMx + ' - ' + i.ItemName
            if (i.FecVcto) nombre += ' - Fecha de vencimiento: ' + i.FecVcto + ' Lote: ' + i.NumLote
            const UnitMedida = [
              {
                value: +i.NumInSale,
                description: i.SalUnitMsr,
                // eslint-disable-next-line eqeqeq
                code: +i.NumInSale == 1 ? 'tYes' : 'tNo'
              }
            ]
            if (+i.NumInSale > 1 && i.InvntryUom) {
              UnitMedida.push({
                value: +i.SalPackUn,
                description: i.InvntryUom,
                code: 'tYes'
              })
            }
            if (this.medicamentos_tabla.length) {
              if (this.medicamentos_tabla.some(a => a.ItemCode === i.CodMx)) {
                const medicamento = this.medicamentos_tabla.find(a => a.ItemCode === i.CodMx)
                if (medicamento.StockMovements.length) {
                  if (!medicamento.StockMovements.filter(j => j.nuevo).some(e => e.BatchNumber === i.NumLote)) {
                    array.push({
                      ...i,
                      name: nombre,
                      UnitMedida
                    })
                  }
                } else {
                  array.push({
                    ...i,
                    name: nombre,
                    UnitMedida
                  })
                }
              } else {
                array.push({
                  ...i,
                  name: nombre,
                  UnitMedida
                })
              }
            } else {
              array.push({
                ...i,
                name: nombre,
                UnitMedida
              })
            }
          }
          this.listado_medicamentos = array
          return array
        })
      } else {
        return _MedicamentosService.listarMedicamentos(params, name).then(({ data }) => {
          let listado = []
          if (data.length) {
            listado = data.filter(a => a.cantstock > 0)
            for (const i of data.filter(a => !a.cantstock)) {
              if (!listado.some(a => a.CodMx === i.CodMx)) {
                listado.push(i)
              }
            }
          }
          const array = []
          for (const i of listado) {
            let nombre = '(Cant: ' + i.cantstock + ') - ' + i.CodMx + ' - ' + i.ItemName
            if (i.FecVcto) nombre += ' - Fecha de vencimiento: ' + i.FecVcto + ' Lote: ' + i.NumLote
            const UnitMedida = [
              {
                value: +i.NumInSale,
                description: i.SalUnitMsr,
                // eslint-disable-next-line eqeqeq
                code: +i.NumInSale == 1 ? 'tYes' : 'tNo'
              }
            ]
            if (+i.NumInSale > 1 && i.InvntryUom) {
              UnitMedida.push({
                value: +i.SalPackUn,
                description: i.InvntryUom,
                code: 'tYes'
              })
            }
            if (this.medicamentos_tabla.length) {
              if (this.medicamentos_tabla.some(a => a.ItemCode === i.CodMx)) {
                const medicamento = this.medicamentos_tabla.find(a => a.ItemCode === i.CodMx)
                if (medicamento.StockMovements.length) {
                  if (!medicamento.StockMovements.some(e => e.BatchNumber === i.NumLote)) {
                    array.push({
                      ...i,
                      name: nombre,
                      UnitMedida
                    })
                  }
                }
              } else {
                array.push({
                  ...i,
                  name: nombre,
                  UnitMedida
                })
              }
            } else {
              array.push({
                ...i,
                name: nombre,
                UnitMedida
              })
            }
          }
          this.listado_medicamentos = array
          return array
        })
      }
    },
    // find (id) {
    //   return new Promise((resolve, reject) => {
    //     _MedicamentosService.find(id).then(({ data }) => {
    //       this.info_medicamento = data
    //       resolve(data)
    //     })
    //   })
    // },
    async findByItemCode (itemCode) {
      let informacionMedicamento = {}
      await _MedicamentosService.getArticulo(itemCode).then(({ data }) => {
        informacionMedicamento = data
      })
      await _CartaAgotadoService.obtenerCartaPorMedicamento({ itemCode }).then(({ data }) => {
        informacionMedicamento = { ...informacionMedicamento, cartaAgotado: data }
      })
      this.info_medicamento = informacionMedicamento
      return Promise.resolve(informacionMedicamento)
    },
    obtenerMedicamentosApartados (id) {
      return _MedicamentosService.getApartadosByOv(id).then(({ data }) => {
        this.medicamentos_apartados = data
        return data
      })
    },
    entregarApartados (payload) {
      return _MedicamentosService.entregarApartados(payload).then(({ data }) => {
        return data
      })
    },
    descargarCargaAgotado (id) {
      _CartaAgotadoService.downloadPdf(id)
    },
    validarMedicamentosEntregadosMenores25Dias (params) {
      return _MedicamentosService.validacionEntregaMismoMdDias(params).then(({ data }) => {
        return data
      })
    }
  }
})
