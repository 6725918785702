import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class MedicamentosService {
  paginate (params, name) {
    return http.get(`${baseUrl}/articulos/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  listarMedicamentos (params, name) {
    return http.get(`${baseUrl}/articulos/search-by-lote/${name}`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/articulos/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  getApartados (params, name) {
    return http.get(`${baseUrl}/articulos/apartados/${name}`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getApartadosByOv (id) {
    return http.get(`${baseUrl}/apartados/apartados-by-ov/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  entregarApartados (payload) {
    return http.post(`${baseUrl}/apartados/entregar-apartados`, payload, {
      headers: {
        loading: true
      }
    })
  }

  getArticulo (code) {
    return http.get(`${baseUrl}/articulos/find-by-item-code/${code}`, {
      headers: {
        loading: true
      }
    })
  }

  getApartadoByMd (md) {
    return http.get(`${baseUrl}/apartados/apartados-by-md/${md}`, {
      headers: {
        loading: true
      }
    })
  }

  sendApartados (data) {
    return http.post(`${baseUrl}/apartados/entregar-apartados`, data, {
      headers: {
        loading: true
      }
    })
  }

  crearArticulo (data) {
    return http.post(`${baseUrl}/articulos`, data, {
      headers: {
        loading: true
      }
    })
  }

  editarArticulo (data) {
    return http.put(`${baseUrl}/articulos/${data.ItemCode}`, data, {
      headers: {
        loading: true
      }
    })
  }

  deleteArticulo (code) {
    return http.delete(`${baseUrl}/articulos/${code}`, {
      headers: {
        loading: true
      }
    })
  }

  getEstReg (tipo) {
    return http.get(`${baseUrl}/articulos/phar-items-sap`, {
      params: {
        Campo: tipo
      },
      headers: {
        loading: true
      }
    })
  }

  getClaMx (tipo) {
    return http.get(`${baseUrl}/articulos/phar-items-sap`, {
      params: {
        Campo: tipo
      },
      headers: {
        loading: true
      }
    })
  }

  getTiposArt (tipo) {
    return Promise.resolve({ data: [{ Value: 1, label: 'Medicamentos' }, { Value: 2, label: 'Insumos' }] })
  }

  getViaAdmon (tipo) {
    return http.get(`${baseUrl}/articulos/phar-items-sap`, {
      params: {
        Campo: tipo
      },
      headers: {
        loading: true
      }
    })
  }

  getLaboratorios (nombre = '') {
    return http.get(`${baseUrl}/laboratorios/search/${nombre}`, {
      params: {
        limit: 100
      },
      headers: {
        loading: true
      }
    })
  }

  getFormFarma (nombre = '') {
    return http.get(`${baseUrl}/forma-farmaceutica/search/${nombre}`, {
      params: {
        limit: 100
      },
      headers: {
        loading: true
      }
    })
  }

  getNiveles (tipo) {
    return http.get(`${baseUrl}/articulos/phar-items-sap`, {
      params: {
        Campo: tipo
      },
      headers: {
        loading: true
      }
    })
  }

  getIndImpuesto () {
    // return http.get(`${baseUrl}/articulos/list-indicadores-imp`, {
    //   headers: {
    //     loading: true
    //   }
    // })
    return Promise.resolve({ data: [{ Value: 'IVAESP', Codigo: 'Gravado' }, { Value: 'IVAEXCLV', Codigo: 'Excluido' }] })
  }

  getRiesgos () {
    // return http.get(`${baseUrl}/articulos/list-riesgos`, {
    //   headers: {
    //     loading: true
    //   }
    // })
    return Promise.resolve({
      data: [{ Value: 1, Codigo: 'Bajo' }, { Value: 2, Codigo: 'Medio' }, {
        Value: 3,
        Codigo: 'Alto'
      }]
    })
  }

  getTemperaturas () {
    return Promise.resolve({
      data: [{ Value: 1, Codigo: 'No > 30°' }, { Value: 2, Codigo: 'No > 8°' }, {
        Value: 3,
        Codigo: 'No > -5°'
      }]
    })
  }

  getGrTerap (nombre = '') {
    return http.get(`${baseUrl}/grupo-terapeutico/search/${nombre}`, {
      params: {
        limit: 100
      },
      headers: {
        loading: true
      }
    })
  }

  getTipoMX (tipo) {
    return http.get(`${baseUrl}/articulos/phar-items-sap`, {
      params: {
        Campo: tipo
      },
      headers: {
        loading: true
      }
    })
  }

  getNumATC (nombre = '') {
    return http.get(`${baseUrl}/atc/search/${nombre}`, {
      params: {
        limit: 100
      },
      headers: {
        loading: true
      }
    })
  }

  getNormaReg (nombre = '') {
    return http.get(`${baseUrl}/norma-regula/search/${nombre}`, {
      params: {
        limit: 100
      },
      headers: {
        loading: true
      }
    })
  }

  getTipoReg (tipo) {
    return http.get(`${baseUrl}/articulos/phar-items-sap`, {
      params: {
        Campo: tipo
      },
      headers: {
        loading: true
      }
    })
  }

  findByGroup (params) {
    return http.get(`${baseUrl}/articulos/find-by-group`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  validacionEntregaMismoMdDias (params) {
    return http.get(`${baseUrl}/articulos/validar-dias-entrega-md-paciente`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
