import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class ClientesService {
  get () {
    return http.get(`${baseUrl}/config-sap/`, {
      headers: {
        loading: true
      }
    })
  }

  update (data) {
    return http.put(`${baseUrl}/config-sap/`, data, {
      headers: {
        loading: true
      }
    })
  }

  crearPermisoTemporalMipres (payload) {
    return http.post(`${baseUrl}/config-sap/crear-permisos-temporales-mipres`, {
      payload,
      headers: {
        loading: true
      }
    })
  }

  findConfigByUser (id) {
    return http.get(`${baseUrl}/config-sap/find-config-by-user/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  actualizarConfigUserMipres (id, payload) {
    return http.put(`${baseUrl}/config-sap/actualizar-registro-configuracion-mipres-usuario/${id}`, payload, {
      header: {
        loading: true
      }
    })
  }
}
