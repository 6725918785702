import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_ORDENES_VENTA
const baseUrlDevoluciones = process.env.VUE_APP_MICROSERVICE_API_DEVOLUCIONES_OV

export default class OrdenService {
  crearOrden (payload) {
    return http.post(`${baseUrl}/ov/crear`, payload, {
      headers: {
        loading: true
      }
    })
  }

  actualizarOrden (payload, id) {
    return http.put(`${baseUrl}/ov/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  obtenerUltimaOrden () {
    return http.get(`${baseUrl}/ov/ultima_orden`, {
      headers: {
        loading: true
      }
    })
  }

  reemplazarMedicamento (id, payload) {
    return http.put(`${baseUrl}/ov/put-line/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  validarNumerosAutorizacion (params) {
    return http.get(`${baseUrl}/ov/listado-autorizaciones-cliente`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  validarOvSync (id) {
    return http.get(`${baseUrl}/ov/validSync/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  listarOrdenes (params) {
    return http.get(`${baseUrl}/ov/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/ov/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  cancelarOrden (id) {
    return http.put(`${baseUrl}/ov/cancelar/${id}`, {}, {
      headers: {
        loading: true
      }
    })
  }

  eliminarLineaOrden (id) {
    return http.put(`${baseUrl}/ov-detalle/eliminar-linea/${id}`, {}, {
      headers: {
        loading: true
      }
    })
  }

  cierreLineaFalso (payload) {
    return http.put(`${baseUrl}/ov-detalle/cerrar-linea-falso`, payload, {
      headers: {
        loading: true
      }
    })
  }

  reabrirLinea (payload) {
    return http.put(`${baseUrl}/ov-detalle/reabrir-linea`, payload, {
      headers: {
        loading: true
      }
    })
  }

  findlogsByOv (ov) {
    return http.get(`${baseUrl}/logs/find-by-ov/${ov}`, {
      headers: {
        loading: true
      }
    })
  }

  findBodegaPpalUsuario () {
    return http.get(`${baseUrl}/ov/find-bodegappal-usuario`, {
      headers: {
        loading: true
      }
    })
  }

  actualizarOvDigitacion (id, payload) {
    return http.put(`${baseUrl}/ov/actualizar-ov-digitacion/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  // DIFERENTE ENV
  devolucion (payload) {
    return http.post(`${baseUrlDevoluciones}/devoluciones`, payload, {
      headers: {
        loading: true
      }
    })
  }
}
